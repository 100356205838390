* {
  box-sizing: border-box;
}

html {
  background-image: linear-gradient(134deg, #ffffff 0%, #f9f9f9 100%);
  height: 100vh;
}

body {
  background-image: linear-gradient(134deg, #ffffff 0%, #f9f9f9 100%);
}

.portfolio-container {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06),
    0px -12px 24px rgba(0, 0, 0, 0.06);
  margin-top: 32px;
  overflow-y: auto;
  min-height: 60vh;
}

@media screen and (max-width: 959px) {
  .portfolio-container h2.project-detail-title {
    font-size: 1.5em;
  }
}
